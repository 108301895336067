<template>
  <div id="home">
    <el-button icon="el-icon-back" @click="back" class="backBtn">返回</el-button>
    <div class="title">
      疏散指示图
    </div>
    <div style="margin-top:15px;display:flex;padding:15px;font-family:楷书;background:rgba(224, 224, 224,0.1)">
      <div style="text-align:left;width:30%;">
        当前位置：
      </div>
      <div style="text-align:center;width:70%">
        {{location}}
      </div>
    </div>

    <div id="content">
      <img id="crt" :src="baseUrl+crtDeviceInfo.crtPictureUrl">
      <span class="el-icon-star-on" :style="{
            left:
              (
                parseFloat(crtDeviceInfo.coordinatex) * divWidth -
                parseFloat(crtDeviceInfo.iconWidth) / 2
              ).toString() + 'px',
            top:
              (
                parseFloat(crtDeviceInfo.coordinatey) * divHeight -
                parseFloat(crtDeviceInfo.iconWidth) / 2
              ).toString() + 'px',
            width: crtDeviceInfo.iconWidth + 'px',
            height: crtDeviceInfo.iconWidth + 'px',
            position: 'absolute',
            'z-index': '1',
            cursor: 'pointer',}">
      </span>
    </div>
  </div>
</template>

<script>
import BaseData from "@/assets/config/BaseData.js";
import scanCodeAlarm from "../../api/manageApi/scanCodeAlarm";
export default {
  data() {
    return {
      crtDeviceInfo: {},
      baseUrl: BaseData.baseUrl,
      crtWidth: '', //crt图片宽度
      crtHeight: '', //crt图片高度
      divWidth: '', //div高度
      divHeight: '', //div高度
      location: '', //二维码设备安装位置
    }
  },
  created() {
    this.crtDeviceInfo = this.$route.params.crtDeviceInfo;
    this.location = this.$route.params.location;
    this.$nextTick(() => {
      document.getElementById('content').style.width = this.divWidth = document.getElementById('crt').width;
      document.getElementById('content').style.height = this.divHeight = document.getElementById('crt').height;
    })
    if (this.crtWidth == 0 || this.crtHeight == 0) {
      this.getQrCodeInfo(this.crtDeviceInfo.deviceInfoId);
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    getQrCodeInfo(deviceInfoId) {
      scanCodeAlarm.getQrCodeInfo(deviceInfoId).then((res) => {
        this.crtDeviceInfo = res.data.data.crtDeviceInfo;
        this.location = res.data.data.location;
        var image = new Image();
        image.src = this.baseUrl + this.crtDeviceInfo.crtPictureUrl;
        document.getElementById('content').style.width = this.divWidth = document.getElementById('crt').width;
        document.getElementById('content').style.height = this.divHeight = document.getElementById('crt').height;
      })
    },

  }
}
</script>

<style>
#home {
  background: #fff;
  width: 24.8%;
}
.title {
  text-align: center;
  font-size: 24px;
  color: #16ba24;
}
#content {
  position: relative;
}
#crt {
  width: 100%;
  position: relative;
}
.backBtn {
  border: none;
}
.el-icon-star-on {
  color: green;
  font-size: 25px;
  animation: breathing 1s linear infinite alternate;
  position: absolute;
}
@keyframes breathing {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}
</style>